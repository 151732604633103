.qualifications__banner {
  width: 100vw;
}

.qualifications__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qualifications__container > * {
  width: 70vw;
}

.qualifications__title {
  width: unset;
  color: #070808;
  font-family: "Roboto", sans-serif;
  font-size: 2.8vw;
  margin-top: 3.5vw;
  margin-bottom: 1vw;
}

.qualifications__sectionTitle {
  color: #181616;
  font-family: "Roboto", sans-serif;
  text-decoration: underline;
  font-size: 2.3vw;
  margin-top: 3.5vw;
  margin-bottom: 1vw;
}

.qualifications__sectionDescription {
  color: #181616;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vw;
  margin-bottom: 1vw;
  margin-left: 3vw;
  line-height: 2.4vw;
}

.qualifications__subtitle {
  width: unset;
  color: #181616;
  font-family: "Roboto", sans-serif;
  font-size: 1.8vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
}

ul {
  color: #181616;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vw;
  margin-bottom: 1vw;
  margin-left: 7vw;
  line-height: 2.3vw;
}
li {
  margin-bottom: 1vw;
}
.subUl {
  margin-left: unset;
  margin-bottom: unset;
}

.qualifications__bodyImg {
  width: 25vw;
}

.qualifications__listImgContainer {
  display: flex;
  align-items: center;
}

ul {
  margin-bottom: 10vw;
}

.tableName {
  font-size: 2.3vw;
}

td,
th {
  border: 1px solid #746f6f;
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #be8f8f;
}
tr:first-child {
  font-weight: bold;
  font-size: 1.4vw;
}
table {
  margin-bottom: 5vw;
}
