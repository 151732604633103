.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__banner {
  width: 100vw;
  height: 18vw;
  object-fit: cover;
  object-position: 3% 60%;
}

.about__title {
  color: #331304;
  font-family: "Roboto", sans-serif;
  font-size: 2.8vw;
  margin-top: 3.5vw;
  margin-bottom: 1vw;
}
.about__statement {
  color: #331304;
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  text-align: center;
  line-height: 3vw;
  margin-top: 3.5vw;
  margin-bottom: 4vw;
  width: 58vw;
}
.about__quote {
  color: #43120c;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
  line-height: 3vw;
  font-size: 1.9vw;
  margin-top: 3.5vw;
  margin-bottom: 5vw;
  width: 52vw;
}
.about__teamTitle {
  color: #6c625a;
  font-family: "Roboto", sans-serif;
  text-decoration: underline;
  text-underline-offset: 0.3vw;
  font-size: 2.5vw;
  margin-top: 3.5vw;
  margin-bottom: 1vw;
}

.about__team {
  width: 75vw;
  margin-bottom: 10vw;
}

svg|a:link,
svg|a:visited {
  cursor: pointer;
}

.email {
  padding: 10px;
}
