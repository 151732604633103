.header__container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0;
}

.header__container > * {
  margin-left: 1.6vw;
  margin-right: 1.6vw;
  margin-bottom: 20px;
  margin-top: 0.4vw;
}

.header__name {
  font-family: "Roboto", sans-serif;
  font-size: 2vw;
  color: #43120c;
}

.header__navButtons {
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  color: #43120c;
}

.header__navButtons:hover {
  background-color: #43120c;
  border-radius: 2px;
  color: white;
}

a {
  text-decoration: none;
}

.header__logo {
  width: 10vw;
}
