.footer__container {
  display: flex;
  color: white;
  background-color: #43120c;
  margin-bottom: 0%;
  font-size: 1vw;
  max-height: 20vw;
}
.footer__leftSide {
  margin-top: 30px;
  display: flex;
  font-family: "Roboto", sans-serif;
}
.footer__leftSide > a > img {
  height: 50%;
  margin-left: 15vw;
}
.footer__name {
  margin-left: 1vw;
}
.footer__links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vw;
  font-family: "Roboto", sans-serif;
  flex-wrap: wrap;
}
.footer__rightSide {
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  margin-left: 10vw;
}

.footer__navButtons {
  margin-left: 1vw;
  margin-right: 1vw;
}

a {
  color: white;
}

.footer__copywright {
  margin-left: 14vw;
  margin-bottom: 1vw;
}
