.contact__banner {
  width: 100vw;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__title {
  margin-top: 3.5vw;
  margin-bottom: 2vw;
  color: #070808;
  font-size: 3vw;
  font-family: "Roboto", sans-serif;
}

.contact__callToAction {
  color: #331304;
  font-size: 2vw;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1vw;
}

.contact__directions {
  margin-bottom: 2vw;
  font-size: 1.5vw;
  color: #331304;
  font-family: "Roboto", sans-serif;
}

.contact__form {
  display: flex;
  flex-direction: column;
  width: 32vw;
  min-width: 260px;
  margin-bottom: 20vw;
}

.contact__form > * {
  margin-bottom: 1vw;
  height: 3vw;
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  text-indent: 1vw;
  border: 1px solid #707070;
}
:-ms-input-placeholder {
  color: #2c1508;
}
::placeholder {
  color: #2c1508;
}
.contact__form > textarea {
  height: 13vw;
}

.contact__form > :nth-child(7) {
  background-color: #c6b09a;
  color: #391610;
  width: 12vw;
}
