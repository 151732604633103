.services__banner {
  width: 100vw;
}
.services__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services__title {
  color: #331304;
  font-family: "Roboto", sans-serif;
  font-size: 2.8vw;
  margin-top: 3.5vw;
  margin-bottom: 1vw;
}
.services__info {
  color: #331304;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vw;
  margin-top: 3.5vw;
  margin-bottom: 4vw;
  width: 58vw;
  line-height: 2.7vw;
}
.serviceHighlight__container {
  display: flex;
  align-items: center;
}
.serviceHighlight__text {
  display: flex;
  flex-direction: column;
  /* width: 40vw; */
}

.service__pictures {
  width: 20vw;
  height: 12.5vw;
  margin-right: 1.5vw;
  padding-left: 1.5vw;
  padding-bottom: 4vw;
}

.serviceHighlightText__title {
  color: #1e3838;
  text-decoration: underline;
  text-decoration-color: #391610;
  text-decoration-thickness: 0.2vw;
  text-underline-offset: 0.7vw;
  font-family: "Roboto", sans-serif;
  font-size: 2.2vw;
}
.serviceHighlightText__description {
  color: #1e3838;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vw;
  margin-top: 1.5vw;
  margin-bottom: 8vw;
  width: 58vw;
  line-height: 2.3vw;
}

#service__lastDiv {
  margin-bottom: 5vw;
}

.clients {
  display: flex;
  column-gap: 2vw;
  margin-bottom: 5vw;
}

.clientList__heading {
  font-size: 3vw;
}

.clients > ul {
  margin: unset;
}
