.location__banner {
  width: 100vw;
}

.location__title {
  margin-top: 3.5vw;
  margin-bottom: 4vw;
  font-family: "Roboto", sans-serif;
  font-size: 2.8vw;
  color: #070808;
}

.location__office {
  margin-bottom: 1vw;
  font-family: "Roboto", sans-serif;
  color: #43120c;
  font-size: 2vw;
}

.location__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location__mapOffice {
  width: 40vw;
  height: 25vw;
  max-width: 600px;
  max-height: 375px;
}

.location__mapNation {
  width: 27vw;
}

.location__bottom {
  margin-top: 8vw;
  margin-bottom: 25vw;
  display: flex;
  align-items: center;
  width: 73vw;
}

.location__bottomText {
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #43120c;
  font-family: "Roboto", sans-serif;
  font-size: 1.4vw;
  line-height: 1.6vw;
}

.location__bottomText > :nth-child(2) {
  margin-top: 2vw;
}

address {
  font-style: unset;
  line-height: 3vw;
  margin-top: 1.9vw;
}
