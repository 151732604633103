.home__banner {
  width: 100vw;
}
.home__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__hook {
  font-size: 3.4vw;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  color: #6c625a;
}
.home__map {
  width: 62.5vw;
}
.emojis {
  width: 7.8vw;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  margin-bottom: 3.5vw;
  margin-top: 2.1vw;
}
.home__quote {
  margin-bottom: 6.9vw;
  width: 55.2vw;
  text-align: center;
  color: #43120c;
  font-family: "Roboto", sans-serif;
  font-size: 1.6vw;
}
.home__infoBox {
  width: 66.7vw;
  height: 16.7vw;
  background-color: #ceb8a2;
  margin-top: 5vw;
  display: flex;
  justify-content: center;
}
.homeInfoBox__left {
  display: flex;
  flex-direction: column;
  width: 31vw;
  align-items: center;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.homeInfoBox__right {
  display: flex;
  flex-direction: column;
  color: #1e3838;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  justify-content: center;
  margin-left: 2vw;
  margin-bottom: 4vw;
}

.homeInfoBox__right > div > a {
  color: #1e3838;
}

.home__callToAction {
  color: #622119;
  font-weight: 700;
  font-size: 2.2vw;
  margin-top: 3vw;
  margin-bottom: 1.5vw;
}

.home__promise {
  color: #6c625a;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.7vw;
}
.homeInfoBox__right > * {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
  font-size: 1.2vw;
}
.home__bottomDesign {
  background-color: rgb(233 223 216);
  height: 20vw;
  width: 100vw;
  margin-bottom: 0%;
  margin-top: -7vw;
  z-index: -5;
}
